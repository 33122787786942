<template>
	<v-flex 
		pa-0 ma-0 sm4 xs12
		:class="{'mt-3': $vuetify.breakpoint.xs}"
	>
		<cards-menu
			:cards="cards"
			:getData="getData"
			:loadingCards="loadingCards"
		/>
		<available-cards
			ref="available_cards"
			:getData="getData"
		/>
	</v-flex>
</template>
<script>
import CardsMenu from './CardsMenu.vue';
import AvailableCards from './AvailableCards.vue';

export default {
	name: 'CardsAndAvailableCards',
	props: ['getData', 'cards', 'loadingCards'],
	components: {CardsMenu, AvailableCards},
	methods: {
		updateAvailableCards() {
			this.$refs.available_cards.updateAvailableCards();
		}
	},
	mounted() {
		this.updateAvailableCards();
	}
}
</script>
