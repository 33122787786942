<template>
	<div fluid>
          <v-card class="mb-3">
            <v-card-title>
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="t('Search')"
                  single-line
                  hide-details
              >
              </v-text-field>
            </v-card-title>
          </v-card>
          <v-card class="my-3">
            <v-subheader>{{ t('Cards') }}</v-subheader>
            <v-data-table
                :headers="headers"
                :items="cards"
                items-per-page="5"
                class="elevation-1"
                :loading="loadingCards"
                :search="search"
                :rows-per-page-text="t('Rows per page')"
                :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.label }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.price + $store.getters.currency }}</td>
                <td class="text-xs-left no-wrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :to="`/cards/${props.item.pk}/details/`" color="info"
                             ripple
                             icon small dark>
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!isManager">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="error" @click="removeCard(props.item.pk)" ripple
                             icon
                             small dark>
                        <v-icon small>close</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Remove') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="pageText" slot-scope="item">
                {{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }}, {{ t('total') }}:
                {{ item.itemsLength }}
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
	</div>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from 'axios';

export default {
	name: 'CardsMenu',
	props: ['cards', 'getData', 'loadingCards'],
	data() {
		return {
			search: '',
		    headers: [
				{
				  text: this.$translate.locale['Label'],
				  align: 'left',
				  value: 'label',
				},
				{
				  text: this.$translate.locale['Price'],
				  align: 'left',
				  value: 'price',
				},
				{
					text: this.$translate.locale['Action'], 
					value: 'action',
					sortable: false
				},
			],
		}
	},
	methods: {
		removeCard(pk) {
		  axios.put(`/api/subscribers/delete/cards/${this.$route.params.id}/`, {card: pk})
			  .then((response) => {
				if (response.status === 200) {
				  this.getData();
				}
			  }).catch((error) => {
			this.text = error.response.data.detail;
			this.snackbar = true;
		  });
		},
	},
	computed: {
		...mapGetters(['isManager'])
	}
}
</script>
