<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md fill-height>
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          top
      >
        {{ t(text) }}
        <v-btn
            flat
            @click="snackbar = false"
        >
          {{ t('Close') }}
        </v-btn>
      </v-snackbar>
      <v-layout
          justify-center
          wrap
      >
        <v-flex sm8 xs12>
          <v-card
              class="mx-auto"
              :loading="loading"
          >
            <v-list two-line subheader dense>
              <v-subheader>
                <v-list-tile-content>
                  {{ t('Details') }}
                </v-list-tile-content>
                <v-dialog v-model="deleteDialog" max-width="290" v-if="!isManager">
                  <template v-slot:activator="{ on }">
                    <v-btn color="error" v-on="on" small icon ripple class="ml-auto mr-0">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">{{ t('Are you sure you want to delete?') }}
                    </v-card-title>
                    <v-card-text>
                      {{ t('This action cannot be undone') }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer>
                      </v-spacer>
                      <v-btn small color="error" @click="deleteDialog = false">{{ t('Close') }}
                      </v-btn>
                      <v-btn outline small color="error" @click="deleteSubscriber">
                        {{ t('Delete') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-subheader>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Name') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.first_name }} {{ details.last_name }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                  v-if="!$store.getters.isPREPAYMENT"
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">account_balance_wallet</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Balance') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.balance + $store.getters.currency }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-dialog 
                    v-model="dialog"
                    max-width="600px" 
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn icon ripple v-on="on">
                        <v-icon color="grey lighten-1">edit</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-form ref="form">
                                <v-text-field
                                    :label="t('Balance')"
                                    type="number" required
                                    :disabled="true"
                                    v-model="details.balance"
                                >
                                </v-text-field>
                                <v-text-field
                                    :label="t('Add money')"
                                    type="number" required
                                    :rules="[rules.counter,rules.required]"
                                    v-model="addBalance"
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer>
                        </v-spacer>
                        <v-btn color="primary" text @click="closeDialog">{{ t('Close') }}</v-btn>
                        <v-btn color="primary" text @click="changeBalance">{{ t('Save') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">card_membership</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Cards price') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ totalPrice + $store.getters.currency }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">mail</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Email') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.email }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">phone</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Phone') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.telephone }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">place</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Address') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.address }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">comment</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Comment') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.comment }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">people</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Reseller') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ details.reseller.email }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <div style="display: flex; padding: 0 16px;">
              <v-tooltip 
                bottom
                v-if="subscriberEditPermission"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :to="`/subscribers/${details.id}/edit/`" color="success" icon ripple
                         small dark class="ml-auto mr-0">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ t('Edit') }}</span>
              </v-tooltip>
            </div>
          </v-card>
			<cards-and-available-cards
				v-if="$vuetify.breakpoint.xs"
				:cards="cards"
				:getData="getData"
				:loadingCards="loadingCards"
				ref="cards_and_available_cards"
			/>
			<logs :logs="details.logs"/>
        </v-flex>
		<cards-and-available-cards
			v-if="!$vuetify.breakpoint.xs"
			:cards="cards"
			:getData="getData"
			:loadingCards="loadingCards"
			ref="cards_and_available_cards"
		/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>

</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
import CardsAndAvailableCards from '../../components/CardsSubscribers/CardsAndAvailableCards.vue'
import Logs from '../../components/Logs/Logs.vue'

export default {
  name: "SubscribersDetails",
  components: {CardsAndAvailableCards, Logs},
  data() {
    return {
      pagination: {
        current: 1,
        total: 20
      },
      page_size: 10,
      next: null,
      previous: null,
      count: 0,
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      loadingCards: true,
      loadingAviableCards: true,
      snackbar: false,
      dialog: false,
      deleteDialog: false,
      search: '',
      searchLogs: '',
	    searchAvailableCards: '',
      text: 'Oops... Something went wrong',
      timeout: 5000,
      newBalance: 0,
      addBalance: 0,
      details: {
        address: "",
        balance: 0,
        cards: Array(0),
        email: '',
        first_name: "",
        id: 0,
        last_name: "",
        reseller: 0,
        comment: '',
        telephone: "",
        logs: [],
      },
      headers: [
        {
          text: this.$translate.locale['Label'],
          align: 'left',
          value: 'label',
        },
        {
          text: this.$translate.locale['Price'],
          align: 'left',
          value: 'price',
        },
        {
          text: this.$translate.locale['Action'], 
          value: 'action',
          sortable: false
        },
      ], 
	  logsOptions: {   
		sortBy: 'date',
		descending: true,
	  }, 
      headersLogs: [   
        { text: this.$translate.locale['Date'], value: 'date' },
        {
          text: this.$translate.locale['Header'],
          align: 'left',
          value: 'log',
        },
      ],
      cards: [],
      aviableCards: [],
      rules: {
        counter: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
        number: value => {
          const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
        required: value => (!!value || value === 0) || this.$translate.locale['Required.'],
      },
      subscriberEditPermission: false,
    }
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.details.cards.forEach((item) => {
        total += item.price
      });
      return total;
    },
    ...mapGetters(['isManager'])
  },
  methods: {
    getData() {
      this.loadingCards = true;
      axios.get(`/api/subscribers/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.details = response.data;
              this.cards = this.details.cards;
              this.newBalance = this.details.balance;
              this.loadingCards = false;
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
	  this.$refs.cards_and_available_cards.updateAvailableCards();
    }, 
    changeBalance() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        this.loading = true;
        const putBody = {
          balance: this.details.balance.toString().replace(/,/g, '.'),
          addBalance: this.addBalance
        };
        axios.put(`/api/subscribers/edit/balance/${this.$route.params.id}/`, putBody)
            .then((response) => {
              if (response.status === 200) {
                this.newBalance = this.details.balance;
                this.loading = false;
                this.dialog = false;
                this.text = "Balance changed!";
                this.snackbar = true;
                this.addBalance = 0;
                this.$store.dispatch('setBalance', response.data.balance);
              }
            }).catch((error) => {
          if (error.response.status === 400) {
            this.text = (
              error.response.data.details
              || "Error while changing balance"
            );
            this.snackbar = true;
            this.dialog = false;
          } else {
            this.text = "Connection error";
            this.snackbar = true;
          }
        });
      }
    },
    closeDialog() {
      this.dialog = false;
      this.addBalance = 0;
      this.details.balance = this.newBalance;
    },
    deleteSubscriber() {
      axios.delete(`/api/subscribers/delete/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.$router.push('/subscribers')
            }
          }).catch((error) => {
        this.text = "Connection error";
        this.snackbar = true;
      });
    },
    getSubscriberEditPermission() {
      axios.get('/api/subscribers/edit_permission/')
        .then(response => {
          if (response.status === 200) {
              this.subscriberEditPermission = response.data.has_permission
          }
        })
    }
  },
  beforeMount() {
      this.getSubscriberEditPermission();
  },
  watch: {
    'addBalance': {
      handler(newValue) {
        console.log(newValue);
        if (newValue === '') {
          this.details.balance = this.newBalance;
        }
        let b = parseFloat(this.newBalance);
        if (newValue) {
          this.details.balance = b + parseFloat(newValue);
        }
      }
    },
    'pagination.current': {
      handler(newValue) {
        this.getData();
      }
    },
    'page_size': {
      handler(val) {
        this.getData();
      }
    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
