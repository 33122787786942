<template>
	<div fluid>
          <v-card class="mb-3">
            <v-card-title>
              <v-text-field
                  v-model="searchAvailableCards"
                  append-icon="search"
                  :label="t('Search')"
                  single-line
                  hide-details
				          @change="handleSearch"
              >
              </v-text-field>
            </v-card-title>
          </v-card>
          <v-card class="my-3">
            <v-subheader>{{ t('Available cards') }}</v-subheader>
            <v-data-table
                :headers="headers"
                :items="aviableCards"
                items-per-page="5"
                class="elevation-1"
                :loading="loadingAviableCards"
                :rows-per-page-text="t('Rows per page')"
                :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">{{ props.item.label }}</td>
                <td class="text-xs-left no-wrap">{{ props.item.price + $store.getters.currency }}</td>
                <td class="text-xs-left no-wrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :to="`/cards/${props.item.pk}/details/`" color="info"
                             ripple
                             icon small dark>
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="success" @click="addCard(props.item.pk)" ripple
                             icon
                             small dark>
                        <v-icon small>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Add') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="pageText" slot-scope="item">
                {{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }}, {{ t('total') }}:
                {{ item.itemsLength }}
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
            <div
                style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
              <v-pagination
                  v-model="pagination.current"
                  :length="pagination.total"
                  :total-visible="5"
              ></v-pagination>
              <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
              </v-select>
            </div>
          </v-card>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	name: 'AvailableCards',
	props: ['getData'],
	data() {
		return {
			loadingAviableCards: true,
			searchAvailableCards: '',
			aviableCards: [],
			pagination: {
				current: 1,
				total: 20
			},
			page_size: 10,
			headers: [
				{
				  text: this.$translate.locale['Label'],
				  align: 'left',
				  value: 'label',
				},
				{
				  text: this.$translate.locale['Price'],
				  align: 'left',
				  value: 'price',
				},
				{
					text: this.$translate.locale['Action'], 
					value: 'action',
					sortable: false
				},
			], 
		}
	},
	methods: {
		addCard(pk) {
		  axios.put(`/api/subscribers/edit/cards/${this.$route.params.id}/`, {card: pk})
			  .then((response) => {
				if (response.status === 200) {
				  this.$store.dispatch('setBalance', response.data.balance);
				  this.getData();
				}
			  }).catch((error) => {
				if (error.response.status === 400) {
				  this.text = "Balance too low";
				  this.snackbar = true;
				} else {
				  this.text = "Connection error";
				  this.snackbar = true;
				}
		  });
		},
		handleSearch() {
		  this.pagination.current = 1;
		  this.updateAvailableCards();
		},
		updateAvailableCards() {
		  this.loadingAviableCards = true;

		  let params = new URLSearchParams({
		    page: this.pagination.current,
			  page_size: this.page_size,
			  id: this.searchAvailableCards,
		  });
		  let url = `/api/subscribers/edit/cards/${this.$route.params.id}/?${params.toString()}`;
		  axios.get(url)
			  .then((response) => {
				if (response.status === 200) {
				  this.aviableCards = response.data.results;
				  this.previous = response.data.previous;
				  this.next = response.data.next;
				  this.count = response.data.count;
				  this.pagination.total = response.data.total_pages;
				  this.loadingAviableCards = false;
				}
			  }).catch((error) => {
			this.text = "Connection error";
			this.snackbar = true;
		  });
		},
	},
	watch: {
		'pagination.current': {
			handler(newValue) {
				this.updateAvailableCards();
			}
		},
		'page_size': {
			handler(val) {
				this.updateAvailableCards();
			}
		},
	}
}
</script>
